<template>
  <ContainerCard :locationMsg="locationMsg" :width="90">
    <div class="search">
      <div class="searchContainer">
        <el-input
          class="input"
          placeholder="请输入职教集团名称"
          v-model="params.keyword"
          clearable
        >
        </el-input>
        <img
          @click="searchHandle"
          class="searchIcon"
          src="../../assets/images/搜索.png"
        />  
        <div
          @click="searchHandle" 
          class="input-button"
        >搜索
        </div>
      </div>
      <!-- 跳转后台 -->
      <el-button type="primary" style="margin-left: 20px;" @click="toBackStage('enterprise')">企业申请</el-button>
      <el-button type="primary" style="margin-left: 20px;" @click="toBackStage('school')">学校申请</el-button>
    </div>
    <div class="Group">
      <div class="GroupItems" v-loading="fullscreenLoading" v-empty="List">
        <div
          class="GroupItem"
          v-for="(item, index) in List"
          :key="index"
          @click="toGroupDeatails(item.billid)"
        >
          <div class="jobName">
            <span class="name">{{ item.name }}</span>
          </div>
          <div class="jobInfo">
            <div class="infoItem">
              <span class="infotitle">牵头院校</span>
              <span class="infoContent" v-show="item.lead_school_name">{{ item.lead_school_name }}</span>
              <span class="infoContent" v-show="!item.lead_school_name">暂无数据</span>
            </div>
          </div>
        </div>
      </div>
      <div class="block">
    </div>
      <div class="pagination" v-if="totalPage > 1">
        <Pagination
          @changeIndex="setCurrentPage"
          :total="total"
          :totalPage="totalPage"
          :pageSize="params.pageSize"
        ></Pagination>
      </div>
    </div>
  </ContainerCard>
</template>
<script>
import Pagination from "../../components/Pagination.vue";
import Location from "../../components/Location.vue";
import ContainerCard from "../../components/newContainerCard.vue";
import {
  getAlliances,
} from "../../api/group/group.js";
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["techfieldList"]),
  },
  data() {
    return {
      List: [],
      locationMsg: [],
      total: 0,
      totalPage: 0,
      fullscreenLoading: false,
      params: {
        keyword: "",
        pageNo: 1,
        pageSize: 6,
      },
    };
  },
  created() {
    this.getData();
  },
  methods: {
    searchHandle() {
      this.getData();
    },
    setCurrentPage(val) {
      this.params.pageNo = val;
      this.getData();
    },
    async getData() {
      let that = this;
      that.fullscreenLoading = true;
      that.List = [];
      let res = await getAlliances(that.params);
      that.List = res.data.items;
      console.log("执教集团数据:",that.List)
      this.total = res.data.totalCount;
      this.totalPage = res.data.totalPage;
      this.totalPage = Math.ceil(res.data.totalCount / this.params.pageSize);
      that.fullscreenLoading = false;
    },
    toGroupDeatails(id) {
      this.$router.push({
        path: "/group/groupDetails",
        query: {
          id: id,
        },
      });
    },
    // 跳转后台
    toBackStage(val) {
      if (val === 'school') {
        // 学校申请
        window.open("https://console.qzcjrh.cn/cjrh/main/schooltoalliance/start.html?processId=d1a1aba290164c82a0283865372ff49a");
      } else if (val === 'enterprise') {
        // 企业申请
        window.open("https://console.qzcjrh.cn/cjrh/main/enterprisetoalliance/start.html?processId=d0df8cf49eb146c2b9196a3789a64158");
      }
    },
  },
  components: {
    Pagination,
    ContainerCard,
    Location,
  },
};
</script>
<style scoped lang="scss">
.pagination {
  display: flex;
  justify-content: center;
  align-content: center;
  height: 118px;
}
.ContainerCard {
  padding: 10px;
}
.Technology,
.Rank {
  // margin-bottom: 20px;
  flex: 1;
  height: unset;
}
.search {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  // height: 248px;
  // background-image: url("https://tse4-mm.cn.bing.net/th/id/OIP-C.-My6wAxkDPZKx3ocBlq5SwHaEK?w=315&h=180&c=7&r=0&o=5&dpr=1.25&pid=1.7");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  // margin-top: 24px;
  // margin-bottom: 20px;
  margin: 24px auto;
  background: #ffffff;
  .searchContainer {
    width: 696px;
    height: 38px;
    background: #ffffff;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    // padding-right: 36px;
    position: relative;
    ::v-deep .el-input__inner{
      border: 1px solid #2885FF;
      padding-left:32px;  
    }
    .input-button{
      width: 72px;
      height: 38px;
      cursor: pointer;
      background: #2885FF;
      border-radius: 0px 6px 6px 0px;
      position: absolute;
      padding: 1px 0;
      right: 0;
      line-height: 38px;
      text-align: center;
      font-size: 16px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #FFFFFF;
    }
    .searchIcon {
      position: absolute;
      left: 0;
      height: 12px;
      width: 12px;
      padding: 13px 13px 13px 12px;
      &:active {
        transform: scale(0.96);
      }
    }
    .input{
      border: none;
    }
    // ::v-deep .el-input__inner {
    //   border: none;
    // }
  }
}
.Group {
  height: 100%;
  width: 1260px;
  padding: 0px 30px;
}

.options {
  height: 80px;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0px 30px;
  // margin: 0px 10px;
  .button {
    margin-left: 275px;
    width: 132px;
    height: 38px;
    background: #2885ff;
    border-radius: 6px;
    font-size: 16px;
    font-family: 思源黑体 CN Medium;
    font-weight: 500;
    color: #ffffff;
    padding: 0px;
    img {
      width: 16px;
      height: 14px;
      margin-right: 12px;
    }
  }
}
.GroupItems {
  width: 1260px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0px 0px;
  // padding-top: 20px;
}
.GroupItem {
  // width: 28%;
  // flex: 1;
  width: 630px;
  height: 158px;
  border-bottom: 1px solid #f2f2f2;
  box-sizing: border-box;
  cursor: pointer;
  // margin: 10px;
  padding: 30px 30px 30px 0px;
  background: #ffffff;
  // box-shadow: 0px 0px 3px 2px rgba(228, 228, 228, 0.5);
  &:nth-child(even) {
    border-right: 1px solid #f2f2f2;
  }
  &:nth-child(odd) {
    border-left: 0px;
    border-right: 0px;
    padding-left: 30px;
  }
  &:first-child {
    border-left: 0px;
    border-right: 0px;
    padding-left: 0px;
  }

  &:hover {
    border: none;
    z-index: 999;
    box-shadow: 0px 12px 40px rgba($color: #000000, $alpha: 0.12);
    padding-left: 30px;
  }
}
// .GroupItem:nth-child(3n+0){
//   margin-right: 0px;
// }
.jobGroup {
  display: flex;
  flex-wrap: wrap;
  .jobType {
    margin: 10px 10px 10px 0;
    padding: 7px 10px;
    font-size: 14px;
    // font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-family: 思源黑体 CN Medium;
    font-weight: 400;
    color: #3e9cff;
    border-radius: 8px;
    border: 1px solid #3e9cff;
  }
}
.jobName {
  .name {
    font-size: 18px;
    font-weight: Medium;
    // font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-family: Medium;
    font-weight: 500;
    color: #101010;
    margin-right: 12px;
  }

  .group {
    font-family: Regular;
    color: #ffffff;
    font-size: 12px;
    line-height: 18px;
    background-color: #2885FF;
    padding: 3px 5px;
    border-radius: 3px;
    margin-right: 12px;
  }
}
.jobInfo {
  margin-top: 20px;
  // background: #f2f2f2;
  // border-radius: 8px;
  // padding: 20px;
  .infoItem {
    margin-bottom: 12px;
    display: flex;
    flex-direction: row;
    @mixin infotext {
      font-size: 12px;
      font-family: Regular;
      font-weight: 400;
      // line-height: 18px;
      display: inline-block;
    }
    .infotitle {
      display: inline-block;
      width: 56px;
      @include infotext;
      color: #8896a7;
      margin-right: 20px;
      height: 12px;
    }
    .infoContent {
      @include infotext;
      color: #404040;
      width: 502px;
      height: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  // .infotitle {
  //   @include infotext;
  // }
  // .infoContent {
  //   @include infotext;
  //   width: 208px;
  // }
}

.block {
  text-align: center;
  margin: 30px 0;
}

@media screen and (max-width: 1100px) {
}

@media screen and (max-width: 768px) {
}
</style>